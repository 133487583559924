import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { BsCarFront } from "react-icons/bs";
import { HiLogout } from "react-icons/hi";
import { RxPerson } from "react-icons/rx";

import classNames from "classnames";
import RegistrationPlate from "components/MyGarage/RegistrationPlate";
import useDimension from "hooks/useDimension";
import useUser from "hooks/useUser";
import carCloudLogo from "public/CarCloud-White-Logo-2.svg";
import moneysupermarket from "public/MoneySuperMarketLogo.png";
import { FaCreditCard } from "react-icons/fa";
import LogoutModal from "../Logout/LogoutModal";
import NavItem from "./NavItem";
import { NavigationItem } from "./types";

const Header = () => {
	const [logoutModalVisible, setLogoutModalVisible] = useState(false);
	const router = useRouter();
	const { isLoggedIn } = useUser();
	const [navItems, setNavItems] = useState<NavigationItem[]>([]);
	const { isTablet } = useDimension();

	const VALID_PATHS = [
		"/vehicle/insurance/date/[reg]",
		"/insurance/entry/[reg]",
		"/vehicle/search/[reg]",
		"/my-garage/add-vehicle",
		"/quick-widget/partner",
		"/quick-widget/base",
		"/finance-prize-draw",
	];

	useEffect(() => {
		setNavItems([
			{
				name: "My Garage",
				link: "/my-garage",
				icon: BsCarFront,
			},
			{
				name: "My Profile",
				link: "/account",
				icon: RxPerson,
			},
			{
				name: "My Subscriptions",
				link: "/subscriptions",
				icon: FaCreditCard,
			},
			{
				name: "Log Out",
				link: "#",
				icon: HiLogout,
				onClick: () => {
					setLogoutModalVisible(!logoutModalVisible);
				},
			},
		]);
	}, [logoutModalVisible]);

	return (
		<>
			<header
				className={classNames(
					"row z-[9999] bg-indigo px-20 py-1 tablet:px-5",
					{ "!bg-[#101D2D]": router.pathname === "/quick-widget/partner" },
					{ "!hidden": router.pathname === "/finance-prize-draw" }
				)}
			>
				{isLoggedIn ? (
					<div className="col-12">
						{VALID_PATHS.includes(router.pathname) ? (
							<div className="row">
								{router.pathname === "/quick-widget/partner" || router.pathname === "/quick-widget/base" ? (
									<div className="ml-8 flex w-full items-center !justify-between gap-4 py-3 tablet:gap-2">
										<Image alt="CarCloud" src={carCloudLogo} height={40} width={140} objectFit="contain" className="cursor-pointer" />
										<Image alt="Experian" src={moneysupermarket} height={40} width={140} objectFit="contain" className="cursor-pointer" />
									</div>
								) : (
									<div className="flex items-center justify-between tablet:flex-col tablet:gap-10">
										<div className="flex items-center gap-4 tablet:-mr-10 tablet:gap-2">
											<Image
												alt="CarCloud"
												src={carCloudLogo}
												height={60}
												width={200}
												objectFit="contain"
												className="cursor-pointer"
												onClick={() => router.push("/my-garage")}
											/>
											{router.pathname === "/vehicle/insurance/date/[reg]" || router.pathname === "/insurance/entry/[reg]" ? (
												<Image alt="Experian" src={moneysupermarket} height={60} width={200} objectFit="contain" className="cursor-pointer" />
											) : (
												//  router.pathname !== "/subscriptions" ? (
												// 	<Image alt="Experian" src={experian} height={60} width={200} objectFit="contain" className="cursor-pointer" />
												// ) :
												<></>
											)}
										</div>
										{(router.pathname === "/vehicle/insurance/date/[reg]" || router.pathname === "/insurance/entry/[reg]") && (
											<div className="tablet:-mr-10">
												<RegistrationPlate vrm={router.query.reg as string} size="sm" />
											</div>
										)}
									</div>
								)}
							</div>
						) : (
							<Navbar className="navbar-dark" expand="lg">
								<Navbar.Brand className="ms-xl-3 tablet:py-0" href="/my-garage">
									<Image alt="CarCloud" src={carCloudLogo} height={isTablet ? 40 : 60} width={isTablet ? 180 : 200} className="cursor-pointer" />
								</Navbar.Brand>
								<Navbar.Toggle aria-controls="basic-navbar-nav">
									<i style={{ color: "#FFF", fontSize: isTablet ? "1.2rem" : "2rem" }} className="navbar-toggler-icon" />
								</Navbar.Toggle>
								<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
									<Nav activeKey={router.pathname}>
										{navItems.map((i: NavigationItem) => (
											<NavItem key={i.name} navItem={i} />
										))}
									</Nav>
								</Navbar.Collapse>
							</Navbar>
						)}
					</div>
				) : (
					<div className="row">
						<div className="flex items-center justify-between tablet:flex-col">
							{(router.pathname === "/quick-widget/partner" || router.pathname === "/quick-widget/base") && (
								<div className="flex w-full items-center !justify-between gap-4 py-3 tablet:-mr-10 tablet:gap-2">
									<Image alt="CarCloud" src={carCloudLogo} height={40} width={140} objectFit="contain" className="cursor-pointer" />
									<Image alt="Experian" src={moneysupermarket} height={40} width={140} objectFit="contain" className="cursor-pointer" />
								</div>
							)}
							{router.pathname == "/quick-widget" && (
								<div className="mx-auto py-6 text-center">
									<h2>Insurance quick quote</h2>
								</div>
							)}
							{router.pathname !== "/quick-widget" && router.pathname != "/quick-widget/partner" && router.pathname != "/quick-widget/base" && (
								<div className="flex items-center gap-4 py-3 tablet:-mr-10 tablet:gap-2">
									<Image
										alt="CarCloud"
										src={carCloudLogo}
										height={60}
										width={200}
										objectFit="contain"
										className="cursor-pointer"
										onClick={() => router.push("/my-garage")}
									/>
									{/* <div className="h-[65px] border-l-2 border-[#9DA2E57E]"></div> */}
									{/* <Image alt="Experian" src={experian} height={60} width={200} objectFit="contain" className="cursor-pointer" /> */}
									<div className="h-[65px] border-l-2 border-[#9DA2E57E]"></div>
									<Image alt="Experian" src={moneysupermarket} height={60} width={200} objectFit="contain" className="cursor-pointer" />
								</div>
							)}

							<div className="tablet:-mr-10">
								{router.pathname === "/user" && router.query.vrm && <RegistrationPlate vrm={router.query.vrm as string} size="sm" />}{" "}
								{router.pathname === "/vehicle/manual-add/[reg]" && <RegistrationPlate vrm={router.query.reg as string} size="sm" />}
							</div>
						</div>
					</div>
				)}
			</header>

			<LogoutModal isVisible={logoutModalVisible} onHide={() => setLogoutModalVisible(!logoutModalVisible)} />
		</>
	);
};

export default Header;
