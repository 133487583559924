import { AnyAction } from "redux";

export interface Quote {
	memberId: string;
	firstName: string;
	surname: string;
	postcode: string;
	dob: string;
	email: string;
	phone: string;
	vrm: string;
	ncb?: number;
	excess?: number;
	keptAtNight?: string;
	typeOfCover?: string;
}

type InsuranceState = {
	quote?: Quote;
};

const initialState: InsuranceState = {
	quote: undefined,
};

export default function insuranceReducer(state = initialState, action: AnyAction) {
	switch (action.type) {
		case "VEHICLE_INSURANCE_QUOTE": {
			const quote = action.insurance;

			const parts = quote.user.dob.split("-");
			const dob = `${parts[2]}/${parts[1]}/${parts[0]}`;

			return {
				quote: {
					memberId: quote.user.id,
					firstName: quote.user.firstName,
					surname: quote.user.surname,
					postcode: quote.user.postcode,
					dob: dob,
					email: quote.user.email,
					phone: quote.user.phone,
					vrm: quote.reg,
					ncb: quote.ncb,
					typeOfCover: quote.typeOfCover,
					keptAtNight: quote.keptAtNight,
					excess: quote.excess,
				},
			};
		}
		default:
			return state;
	}
}
