import { useState } from "react";

import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";

import { BaseModalProps } from "model/props/modal";

import Downgrade from "./DowngradeSteps/Downgrade";
import Payment from "./DowngradeSteps/Payment";

type LockedAccountModalProps = BaseModalProps & { isUnpaid?: boolean };

export default function LockedAccountModal({ isVisible, onHide, isUnpaid = false }: LockedAccountModalProps) {
	const [step, setStep] = useState(1);

	return (
		<Modal show={isVisible} onHide={onHide} centered className="z-[10000]" size="lg" backdrop="static">
			<Modal.Body className="flex p-0 text-center laptop:flex-col-reverse">
				<div className="!w-full p-12 sm:px-12 laptop:px-5">
					<RxCross2 className="absolute right-5 top-5 cursor-pointer text-xl opacity-20" onClick={onHide} />
					{step === 1 && <Payment setStep={setStep} isUnpaid={isUnpaid} />}
					{step === 2 && <Downgrade isUnpaid={isUnpaid} />}
				</div>
			</Modal.Body>
		</Modal>
	);
}
