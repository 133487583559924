import { createSelector, createSlice } from "@reduxjs/toolkit";
import { calculateAgeWithString } from "helpers/date";
import { LoadingStatus } from "model/enum/loading";
import { Profile } from "model/object/profile";
import { HYDRATE } from "next-redux-wrapper";
import type { RootState } from "../index";

export interface UserState {
	isLoggedIn: boolean;
	userRef: string;
	msmRef: string;
	profile: Profile | null;
	loadingStatus: LoadingStatus;
}

const initialState: UserState = {
	isLoggedIn: false,
	userRef: "",
	msmRef: "",
	profile: null,
	loadingStatus: LoadingStatus.LOADING,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		addUserInfo: (state, action) => {
			state.profile = action.payload.profile;
			state.loadingStatus = LoadingStatus.SUCCESS;
			state.isLoggedIn = true;
		},
		updateUserInfo: (state, action) => {
			const { noClaimsBonus, homeOwner, licenseType, ...updatedProfile } = action.payload;

			updatedProfile.msm = {
				noClaims: noClaimsBonus,
				homeOwner,
				licenseType,
			};

			state.profile = {
				...state.profile,
				...updatedProfile,
			};
		},
		updateLoginState: (state, action) => {
			state.isLoggedIn = action.payload.auth;
			state.userRef = action.payload.ref;
			state.msmRef = action.payload.msm;
		},
		logoutUser: state => {
			state.isLoggedIn = false;
			state.userRef = "";
			state.profile = null;
			state.loadingStatus = LoadingStatus.IDLE;
		},
	},
	extraReducers: {
		[HYDRATE]: (state, action) => {
			state.isLoggedIn = action.payload.user.isLoggedIn;
			state.userRef = action.payload.user.userRef;
			state.msmRef = action.payload.user.msmRef;
		},
	},
});

export const { addUserInfo, updateUserInfo, updateLoginState, logoutUser } = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export const selectProfile = (state: RootState) => state.user.profile;
export const selectAuth = (state: RootState) => state.user.isLoggedIn;
export const selectUserMSMInfo = createSelector([selectProfile], profile => {
	if (!profile) {
		// If profile is null, return an empty object
		return {};
	}

	return {
		driverAge: profile.dob ? calculateAgeWithString(profile.dob) : null,
		homeOwner: profile.msm?.homeOwner ?? false,
		noClaimsDuration: profile.msm?.noClaims ?? 0,
		postcode: profile.postcode,
		provisionalLicence: profile.msm?.licenseType === "provisional",
	};
});

export default userSlice.reducer;
