import backgroundStyles from "./background-clouds.module.css";

const clouds = ["cloudOne", "cloudTwo", "cloudThree", "cloudFour"];

const BackgroundClouds = () => {
	return (
		<>
			{clouds.map(cloud => (
				<div key={cloud} className={`${backgroundStyles[cloud]}`} />
			))}
		</>
	);
};

export default BackgroundClouds;
