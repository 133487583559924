import { combineReducers } from "redux";

import insuranceReducer from "components/Insurance/reducer";
import { mainApi } from "store/services/main";

import vehicleFinanceReducer from "./financeReducer";
import multiCarReducer from "./multiCarReducer";
import reportReducer from "./reportReducer";
import userReducer from "./userReducer";
import vehicleReducer from "./vehicleReducer";

export default combineReducers({
	vehicles: vehicleReducer,
	insurance: insuranceReducer,
	vehicleFinance: vehicleFinanceReducer,
	user: userReducer,
	report: reportReducer,
	multiCar: multiCarReducer,
	[mainApi.reducerPath]: mainApi.reducer,
});
