import { PrimaryCta } from "components/Buttons";
import useUser from "hooks/useUser";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const LogoutModal = ({ isVisible, onHide }: any) => {
	const { logout } = useUser();
	const [isLoggingOut, setIsLoggingOut] = useState(false);

	return (
		<Modal
			show={isVisible}
			onHide={() => {
				if (!isLoggingOut) {
					onHide();
				}
			}}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			contentClassName="modal-content"
			className="z-[10000]"
		>
			<Modal.Body className="px-5 pb-3 text-center">
				<span className="fw-bold fs-5">Are you sure you want to log out?</span>

				<div className="mt-3 flex gap-10">
					<div className="ml-auto">
						<PrimaryCta
							disabled={isLoggingOut}
							text="Yes"
							onClick={async () => {
								setIsLoggingOut(true);
								await logout();
								onHide();
								setIsLoggingOut(false);
							}}
							isSubmitting={isLoggingOut}
							className="!px-8"
						/>
					</div>
					<div className="mr-auto">
						<PrimaryCta disabled={isLoggingOut} text="No" onClick={() => onHide()} className="!px-8" />
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default LogoutModal;
