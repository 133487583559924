import { createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "model/enum/loading";
import { MultiCarStatus } from "model/object/multicar";
import { Subscription } from "model/object/subscription";

import { RootState } from "../index";

export interface MultiCarState {
	status: MultiCarStatus;
	subscription: Subscription;
	loadingStatus: LoadingStatus;
}

const initialState: MultiCarState = {
	status: null as any,
	subscription: null as any,
	loadingStatus: LoadingStatus.LOADING,
};

export const multiCarSlice = createSlice({
	name: "multiCar",
	initialState,
	reducers: {
		setMultiCar: (state, action) => {
			state.status = action.payload.multiCarStatus;
			state.subscription = action.payload.subscription;
			state.loadingStatus = LoadingStatus.SUCCESS;
		},
		toggleVehicleLimit: state => {
			state.status.vehicleLimitReached = false;
		},
	},
});

export const { setMultiCar, toggleVehicleLimit } = multiCarSlice.actions;
export const selectMultiCar = (state: RootState) => state.multiCar;
export const selectSubscription = (state: RootState) => state.multiCar.subscription;
export default multiCarSlice.reducer;
