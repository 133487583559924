import { createSlice } from "@reduxjs/toolkit";
import { getStorageValue, setStorageValue } from "helpers";
import { LoadingStatus } from "model/enum/loading";
import { VehicleV2 } from "model/object/vehicle";
import type { RootState } from "../index";

export interface VehiclesState {
	vehicles: VehicleV2[];
	selectedIndex: number;
	selectedVehicle: VehicleV2 | null;
	selectedRegistration: string;
	loadingStatus: LoadingStatus;
}

const initialState: VehiclesState = {
	selectedVehicle: null,
	selectedRegistration: "",
	selectedIndex: 0,
	vehicles: [],
	loadingStatus: LoadingStatus.LOADING,
};

export const vehicleSlice = createSlice({
	name: "vehicle",
	initialState,
	reducers: {
		addVehicles: (state, action) => {
			const localStorageData = getStorageValue("registration");
			const [selectedVehicle, vehicleIndex] = getSelectedVehicle(localStorageData, action.payload);

			state.vehicles = action.payload;
			state.selectedVehicle = selectedVehicle;
			state.selectedRegistration = selectedVehicle.registration;
			state.selectedIndex = vehicleIndex;
			state.loadingStatus = LoadingStatus.SUCCESS;

			setStorageValue("registration", selectedVehicle.registration);
		},
		setRegistration: (state, action) => {
			state.selectedRegistration = action.payload;
			setStorageValue("registration", action.payload);
		},
		setSelectedVehicle: (state, action) => {
			state.selectedVehicle = action.payload.vehicle;
			state.selectedIndex = action.payload.index;
			state.selectedRegistration = action.payload.vehicle.registration;

			setStorageValue("registration", action.payload.vehicle.registration);
		},
		updateSelectedVehicle: (state, action) => {
			const vehicleCopy = { ...state.selectedVehicle, ...action.payload };
			const vehiclesCopy = [...state.vehicles];

			vehiclesCopy[state.selectedIndex] = vehicleCopy;

			state.selectedVehicle = vehicleCopy;
			state.vehicles = vehiclesCopy;
		},
		changeRegistration: (state, action) => {
			const [vehicle, index] = getSelectedVehicle(action.payload, state.vehicles);
			state.selectedRegistration = action.payload;
			state.selectedVehicle = vehicle;
			state.selectedIndex = index;

			setStorageValue("registration", action.payload);
		},
		deleteVehicle: (state, action) => {
			const [_, index] = getSelectedVehicle(action.payload, state.vehicles);
			const vehiclesCopy = [...state.vehicles];
			const prevVehicle = vehiclesCopy[index === 0 ? index + 1 : index - 1];
			state.vehicles = vehiclesCopy.filter(v => v.registration !== action.payload);
			state.selectedRegistration = prevVehicle.registration;
			state.selectedVehicle = prevVehicle;
			state.selectedIndex = index === 0 ? index + 1 : index - 1;

			setStorageValue("registration", prevVehicle.registration);
		},
		bulkDeleteVehicle: (state, action) => {
			const vehicleToKeep = action.payload;
			state.vehicles = [vehicleToKeep];
			state.selectedRegistration = vehicleToKeep.registration;
			state.selectedVehicle = vehicleToKeep;
			state.selectedIndex = 0;

			setStorageValue("registration", vehicleToKeep.registration);
		},
		updateMSMQuote: (state, action) => {
			const quote = action.payload.quote;
			const reg = action.payload.vrm;
			const vehiclesCopy = [...state.vehicles];

			const [vehicle, index] = getSelectedVehicle(reg, state.vehicles);
			vehicle.insurance.msmUpper = quote.msmUpper;
			vehicle.insurance.msmLower = quote.msmLower;
			vehiclesCopy[index] = vehicle;

			state.vehicles = vehiclesCopy;

			// Apply changes on selected vehicle
			if (index === state.selectedIndex) {
				state.selectedVehicle = vehicle;
			}
		},
	},
});

export const { addVehicles, setRegistration, setSelectedVehicle, updateSelectedVehicle, changeRegistration, deleteVehicle, bulkDeleteVehicle, updateMSMQuote } =
	vehicleSlice.actions;
export const selectVehicles = (state: RootState) => state.vehicles;
export const selectRegistration = (state: RootState) => state.vehicles.selectedRegistration;
export default vehicleSlice.reducer;

const getSelectedVehicle = (registration: string, vehicles: VehicleV2[]): [VehicleV2, number] => {
	const vehicleIndex = vehicles.findIndex(vehicle => vehicle.registration.toUpperCase() === registration.trim().toUpperCase());
	if (registration === "" || vehicleIndex === -1) {
		return [vehicles[0], 0];
	}

	return [vehicles[vehicleIndex], vehicleIndex];
};
