import { PrimaryCta } from "components/Buttons";
import UnderlinedButton from "components/Buttons/UnderlinedButton";
import { getStorageValue, setStorageValue } from "helpers";
import { api } from "helpers/api";
import useVehicles from "hooks/useVehicles";
import { useRouter } from "next/router";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { bulkDeleteVehicle } from "store/reducers/vehicleReducer";
import RemoveVehicleSection from "./RemoveVehicleSection";

type DowngradeProps = {
	isUnpaid: boolean;
};

function Downgrade({ isUnpaid }: DowngradeProps) {
	const router = useRouter();
	const dispatch = useDispatch();
	const { vehicles } = useVehicles();
	const [isLoading, setIsLoading] = useState(false);
	const [primaryVehicle, setPrimaryVehicle] = useState(getStorageValue("primary-vehicle") || vehicles[0].registration);

	const handleSelect = (vrm: string) => {
		setPrimaryVehicle(vrm);
		setStorageValue("primary-vehicle", vrm);
	};

	const handleSubmit = async () => {
		setIsLoading(true);

		try {
			const regs = vehicles.filter(v => v.registration !== primaryVehicle).map(v => v.registration);
			await api.post(`/vehicle/bulk-delete`, {
				registrations: regs,
				action: "now",
			});

			dispatch(bulkDeleteVehicle(vehicles.find(v => v.registration === primaryVehicle)));
			setIsLoading(false);

			router.reload();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className="px-4">
			<div className="mb-12 flex flex-col gap-3">
				<p className="text-left text-[20px] font-bold">{isUnpaid ? "Restore" : "Downgrade"} account</p>
				<p className="text-left text-[14px] font-[550] mobileL:text-center">
					To {isUnpaid ? "restore" : "downgrade"} your account, please select your primary vehicle. The remaining vehicles will be removed from your account. You can add
					them again by purchasing a{isUnpaid && " new"} subscription.
				</p>
			</div>
			<div className="mb-8 flex flex-col gap-2">
				{vehicles.map((vehicle, index) => (
					<RemoveVehicleSection key={index} vehicle={vehicle} isPrimary={primaryVehicle === vehicle.registration} onSelect={() => handleSelect(vehicle.registration)} />
				))}
			</div>
			<PrimaryCta text="Continue" className="mx-auto" onClick={handleSubmit} isSubmitting={isLoading} hasArrowRight />
			<UnderlinedButton text="Purchase Subscription" className="mx-auto mt-3" onClick={() => router.push("/subscriptions")} />
		</div>
	);
}

export default Downgrade;
