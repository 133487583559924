import classNames from "classnames";
import { Button } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";

type PrimaryCtaProps = {
	text: string | Element;
	onClick?: () => void;
	disabled?: boolean;
	type?: "submit" | "reset" | "button" | undefined;
	style?: object;
	icon?: string;
	isSubmitting?: boolean;
	className?: string;
	hasArrowRight?: boolean;
	fullWidth?: boolean;
	sent?: boolean;
	arrowRightClassName?: string;
	underlineClassName?: string;
};

const UnderlinedButton = ({
	text,
	onClick,
	disabled = false,
	type = "button",
	style,
	icon,
	isSubmitting = false,
	className = "",
	hasArrowRight = false,
	fullWidth = false,
	sent = false,
	arrowRightClassName = "",
	underlineClassName = "",
}: PrimaryCtaProps) => {
	return (
		<Button
			onClick={onClick}
			disabled={disabled || isSubmitting}
			className={classNames(`submit bg-transparent flex items-center justify-center px-4 py-2.5 !text-base !text-blue focus:bg-blue ${className}`, {
				"!w-max": !fullWidth,
				"!w-auto": fullWidth,
			})}
			type={type}
			style={style}
		>
			<>
				{icon ? (
					<i className={`bi ${icon} !text-blue underline`} style={{ fontSize: "1.3em" }}>
						<span style={{ color: "#fff" }} className="ps-2">
							{text}
						</span>
					</i>
				) : (
					<div className="flex flex-col !text-blue">
						<div className="align-items-center flex">
							<span>{text}</span>
							{hasArrowRight && <FiArrowRight className={`ml-2 text-lg ${arrowRightClassName}`} />}
						</div>

						<div className={`-mt-1 h-[2px] w-full bg-blue ${underlineClassName}`} />
					</div>
				)}
			</>
		</Button>
	);
};

export default UnderlinedButton;
