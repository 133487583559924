import { useEffect, useState } from "react";

import classNames from "classnames";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import { CookieBannerUniversal } from "react-cookie-banner";

import LockedAccountModal from "components/Subscriptions/LockedAccountModal";
import { cookieBannerStyles } from "constants/styles";
import { setStorageValue } from "helpers";
import useMultiCar from "hooks/useMultiCar";
import useUser from "hooks/useUser";
import useVehicles from "hooks/useVehicles";
import { DefaultProps } from "model/props/auth";
import BlurredBg from "public/img/splash/blurred.png";

import BackgroundClouds from "../Clouds/BackgroundClouds";
import Analytics from "./Analytics";
import Footer from "./Footer";
import CentralHeader from "./Header";

const Layout = (props: DefaultProps) => {
	const PUBLIC_PATHS = [
		"/",
		"/login",
		"/user",
		"/maintenance",
		"/quick-widget",
		"/quick-widget/grey",
		"/quick-widget/partner",
		"/quick-widget/base",
		"/subscriptions/processing",
		"/subscriptions/redirecting",
		"/subscriptions/updating",
		"/vehicle/manual-add",
		"/password/forgot",
		"/finance-prize-draw",
		"/sentry-example-page",
	];
	const router = useRouter();
	const isQuickWidgetRoute = router.pathname === "/quick-widget" || router.pathname === "/quick-widget/grey";
	const isQuickWidgetPartnerRoute = router.pathname === "/quick-widget/partner";
	const { fetchUser, isLoggedIn, profile } = useUser();
	const { fetchVehicles } = useVehicles();
	const { fetchMultiCar, subscription } = useMultiCar();
	const [showBanner, setShowBanner] = useState(true);

	const handleBannerDismiss = () => {
		setShowBanner(false);
		setStorageValue("cookie-confirm", "yes");
		document.cookie = "user-has-accepted-cookies=true; path=/; max-age=31536000"; // Cookie expires in 1 year
	};

	useEffect(() => {
		if (isLoggedIn && profile === null) {
			fetchUser();
			fetchVehicles();
			fetchMultiCar();
		} else if (profile === null && !PUBLIC_PATHS.includes(router.pathname)) {
			router.push("/login");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, profile, router]);

	return (
		<>
			<Head>
				<title>CarCloud Community Account</title>
				<meta
					name="description"
					content="Check your vehicle history, get reminders for tax and MOT dates, store all your car info, and find cheaper insurance from the Car Cloud App"
				/>
				<link rel="icon" href="/favicon.ico" />
			</Head>
			<div className={classNames("flex min-h-[95vh] flex-col", { "!min-h-[100vh]": router.pathname === "/finance-prize-draw" })}>
				{!isQuickWidgetRoute && <CentralHeader />}
				<BackgroundClouds />
				<div className="flex flex-grow flex-col">
					<div className="z-[999] flex flex-grow flex-col">
						{isLoggedIn && !router.pathname.startsWith("/subscriptions") && !PUBLIC_PATHS.includes(router.pathname) && subscription?.isLocked ? (
							// Do not render pages if profile is locked
							<>
								<Image src={BlurredBg} alt="locked" />
								<LockedAccountModal isUnpaid={subscription?.status === "unpaid"} onHide={() => {}} isVisible />
							</>
						) : (
							props.children
						)}
					</div>
				</div>
				{!isQuickWidgetRoute && <Footer />}
				{showBanner && (
					<CookieBannerUniversal
						className="!h-fit"
						styles={cookieBannerStyles}
						message='This website uses cookies that help the website to function. By clicking "Accept", you agree to the storing of cookies on your device to enhance site features, analyse site usage and assist in our marketing efforts.'
						dismissOnScroll={false}
						onAccept={() => handleBannerDismiss()}
						cookie="user-has-accepted-cookies"
					/>
				)}
			</div>
			{process.env.NEXT_PUBLIC_ENVIRONMENT === "prod" && <Analytics showBanner={showBanner} />}
		</>
	);
};

export default Layout;
