import { calculateAge } from "helpers/date";
import { useSelector } from "react-redux";
import { VehiclesState, selectVehicles } from "store/reducers/vehicleReducer";
import { useLazyGetAllVehiclesQuery, useUpdateVehicleMSMQuoteMutation } from "store/services/main";

const useVehicles = () => {
	const vehicleState: VehiclesState = useSelector(selectVehicles);
	const [fetchVehicles] = useLazyGetAllVehiclesQuery();
	const [updateVehicleInsurance, _] = useUpdateVehicleMSMQuoteMutation();

	const fetchAllQuotes = (dob: string, homeOwner: boolean, postcode: string, noClaimsDuration: number, licenseType: "provisional" | "full") => {
		vehicleState.vehicles.forEach(v => {
			if (v.insurance.msmLower === -1) {
				// No quote yet
				updateVehicleInsurance({
					registration: v.registration.toLowerCase(),
					driverAge: calculateAge(new Date(dob)),
					homeOwner,
					postcode,
					noClaimsDuration,
					provisionalLicence: licenseType === "provisional",
				});
			}
		});
	};

	const fetchVehicleQuote = (vrm: string, dob: string, homeOwner: boolean, postcode: string, noClaimsDuration: number, licenseType: "provisional" | "full") => {
		updateVehicleInsurance({
			registration: vrm.toLowerCase(),
			driverAge: calculateAge(new Date(dob)),
			homeOwner,
			postcode,
			noClaimsDuration,
			provisionalLicence: licenseType === "provisional",
		});
	};

	return { ...vehicleState, fetchVehicles, fetchAllQuotes, fetchVehicleQuote };
};

export default useVehicles;
