import { useEffect } from "react";

import { deleteCookie, getCookie } from "cookies-next";
import { enGB } from "date-fns/locale";
import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import { decode } from "jsonwebtoken";
import App, { AppProps } from "next/app";
import { useRouter } from "next/router";
import { Cookies, CookiesProvider } from "react-cookie-banner";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";

import Layout from "components/Layout/Layout";
import { DefaultProps } from "model/props/auth";
import { wrapper } from "store";
import { updateLoginState } from "store/reducers/userReducer";

import "bootstrap/dist/css/bootstrap.min.css";
import { SessionProvider } from "next-auth/react";
import "react-datepicker/dist/react-datepicker.css";
import "styles/globals.css";

const cookies = new Cookies();

registerLocale("en-GB", enGB);
setDefaultLocale("en-GB");
dayjs.extend(AdvancedFormat);

type MyAppProps = AppProps & {
	pageProps: DefaultProps;
};

function MyApp({ Component, ...rest }: MyAppProps) {
	const IS_MAINTENANCE = false;
	const router = useRouter();

	const { store, props } = wrapper.useWrappedStore(rest);

	useEffect(() => {
		if (IS_MAINTENANCE) {
			router.replace("/maintenance");
		}
	}, [IS_MAINTENANCE, router]);

	return (
		<SessionProvider basePath="/api/auth/sso" session={props.pageProps.session}>
			<GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY as string}>
				<Provider store={store}>
					<CookiesProvider cookies={cookies}>
						<Layout {...props.pageProps}>
							<Component {...props.pageProps} />
						</Layout>
					</CookiesProvider>
				</Provider>
			</GoogleReCaptchaProvider>
		</SessionProvider>
	);
}

MyApp.getInitialProps = wrapper.getInitialPageProps(store => async (appContext: any) => {
	const appProps = await App.getInitialProps(appContext);
	const storeAuth = store.getState().user.isLoggedIn;

	if (!storeAuth) {
		const ctx = appContext.ctx;
		const refreshToken = getCookie("refresh_token", { req: ctx.req, res: ctx.res });
		const claims = decode(refreshToken?.valueOf() as string) as any;
		const auth = Boolean(refreshToken) && Boolean(claims?.refId);

		store.dispatch(updateLoginState({ auth, ref: claims?.refId || "", msm: claims?.msmId || "" }));

		if (!auth) {
			deleteCookie("refresh_token", {
				req: ctx.req,
				res: ctx.res,
			});
		}
	}

	return { ...appProps };
});

export default wrapper.withRedux(MyApp);
