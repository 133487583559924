export type AGREEMENT_TYPE_ENUM_TYPE = "HIRE PURCHASE" | "PCP" | "LEASE" | "CONTRACT HIRE" | "CONDITIONAL SALE" | "MISCELLANEOUS" | "UNKNOWN" | "PERSONAL LOAN" | "NON FINANCE";
export type AGREEMENT_TYPE_ENUM_VALUE = "HP" | "PCP" | "L" | "CH" | "CS" | "MSC" | "UNK" | "PL" | "NF";
export const AGREEMENT_TYPE_ENUM = {
	"HIRE PURCHASE": {
		label: "Hire Purchase",
		value: "HP",
		lowerCaseValue: "hire purchase",
	},
	PCP: {
		label: "PCP",
		value: "PCP",
		lowerCaseValue: "PCP",
	},
	LEASE: {
		label: "Lease",
		value: "L",
		lowerCaseValue: "lease",
	},
	"CONTRACT HIRE": {
		label: "Contract Hire",
		value: "CH",
		lowerCaseValue: "contract hire",
	},
	"CONDITIONAL SALE": {
		label: "Conditional Sale",
		value: "CS",
		lowerCaseValue: "conditional sale",
	},
	"PERSONAL LOAN": {
		label: "Personal Loan",
		value: "PL",
		lowerCaseValue: "personal loan",
	},
	MISCELLANEOUS: {
		label: "Miscellaneous",
		value: "MSC",
		lowerCaseValue: "miscellaneous",
	},
	UNKNOWN: {
		label: "Unknown",
		value: "UNK",
		lowerCaseValue: "unknown",
	},
	"NON FINANCE": {
		label: "Non Finance",
		value: "NF",
		lowerCaseValue: "non finance",
	},
};
