import isEmpty from "lodash/isEmpty";
import { Profile } from "model/object/profile";
import { ExperianReport, ValuationReport } from "model/object/report";

export const computeEquity = (
	monthlyPayment: number,
	settlementFigure: number,
	balloonPayment: number,
	valuation: ValuationReport,
	experianFinance: ExperianReport,
	monthsLeft?: string
) => {
	const { tradeValuation, retailValuation, marketValue } = valuation;
	const tradeValue = tradeValuation > 0 || tradeValuation != 0 ? tradeValuation : marketValue;

	const averageMarketValue = (tradeValue + retailValuation) / 2;
	const calculatedSettlement = monthlyPayment * experianFinance.monthsLeft;
	let equity;

	if (experianFinance.isLoan) {
		if (!isEmpty(monthsLeft)) {
			const parsedMonthsLeft = parseInt(monthsLeft!);
			equity = averageMarketValue - monthlyPayment * parsedMonthsLeft;
		} else {
			equity = averageMarketValue;
		}
	} else if (experianFinance.agreementType === "HIRE PURCHASE") {
		if (settlementFigure === 0) {
			equity = averageMarketValue - calculatedSettlement;
		} else {
			equity = averageMarketValue - settlementFigure;
		}
	} else {
		if (settlementFigure === 0) {
			equity = averageMarketValue - calculatedSettlement - balloonPayment;
		} else {
			equity = averageMarketValue - settlementFigure;
		}
	}

	return {
		equityValue: equity,
		isPositiveEquity: equity > 0,
	};
};

export const calculateProfilePercentage = (profile: Profile) => {
	let profilePct = 0;
	if (profile?.email) {
		profilePct += 0.1;
	}
	if (profile?.firstName) {
		profilePct += 0.15;
	}
	if (profile?.surname) {
		profilePct += 0.15;
	}
	if (profile?.phone) {
		profilePct += 0.15;
	}
	if (profile?.address1) {
		profilePct += 0.15;
	}
	if (profile?.postcode) {
		profilePct += 0.15;
	}
	if (profile?.dob) {
		profilePct += 0.15;
	}

	return Math.round(profilePct * 100);
};

export function generateRandom6AlphaNumeric() {
	const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";
	for (let i = 0; i < 6; i++) {
		const randomIndex = Math.floor(Math.random() * chars.length);
		result += chars[randomIndex];
	}
	return result;
}

export function roundToTwo(num: number) {
	return Math.round(num * 100) / 100;
}
