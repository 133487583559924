import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getDeviceId, getWebSource } from "helpers/storage";
import { BasicAuth, BasicResponse, CreateBasicAuth } from "model/object/auth";
import { MultiCarSubscription } from "model/object/multicar";
import { Profile, UpdateProfile, UpdateProfileResponse } from "model/object/profile";
import { FinanceReport, FullForecast } from "model/object/report";
import { MSMInsuranceResponse, VehicleV2 } from "model/object/vehicle";
import { setMultiCar } from "store/reducers/multiCarReducer";
import { updateReport, updateReportId } from "store/reducers/reportReducer";
import { addUserInfo, logoutUser, updateLoginState, updateUserInfo } from "store/reducers/userReducer";
import { addVehicles, updateMSMQuote } from "store/reducers/vehicleReducer";
import { EstimateAnswers } from "validators/estimate";

export const mainApi = createApi({
	reducerPath: "mainApi",
	baseQuery: fetchBaseQuery({
		baseUrl: "/api",
		credentials: "include",
		prepareHeaders: headers => {
			headers.set("DeviceId", getDeviceId());
			headers.set("web_source", getWebSource());
			return headers;
		},
	}),
	endpoints: builder => ({
		getAllVehicles: builder.query<VehicleV2[], void>({
			query: () => ({ url: "/vehicles", method: "GET" }),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				const { data } = await queryFulfilled;
				dispatch(addVehicles(data));
			},
		}),
		getUserInfo: builder.query<Profile, void>({
			query: () => ({ url: "/profile", method: "GET" }),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(addUserInfo({ profile: data }));
				} catch (error) {
					const status = (error as any).error.status as number;
					if (status === 401) {
						// Unauthorized
						window.location.href = "/login";
					}
				}
			},
		}),
		getMultiCar: builder.query<MultiCarSubscription, void>({
			query: () => ({ url: "/multi-car", method: "GET" }),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				const { data } = await queryFulfilled;
				dispatch(setMultiCar(data));
			},
		}),
		getReport: builder.query<FinanceReport, string>({
			query: reportId => ({ url: `/report?reportId=${reportId}`, method: "GET" }),
			async onQueryStarted(reportId, { dispatch, queryFulfilled }) {
				dispatch(updateReportId(reportId));
				const { data } = await queryFulfilled;
				dispatch(updateReport(data));
			},
		}),
		getFullForecast: builder.query<FullForecast, string>({
			query: vrm => ({ url: `/report/forecast?vrm=${vrm}`, method: "GET" }),
			async onQueryStarted(vrm, { queryFulfilled }) {
				if (vrm) {
					await queryFulfilled;
				}
			},
		}),
		updateUserProfile: builder.mutation<UpdateProfileResponse, UpdateProfile>({
			query: args => ({ url: "/user/update", method: "POST", body: args }),
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(updateUserInfo(args));
			},
		}),
		updateVehicleMSMQuote: builder.mutation<MSMInsuranceResponse, EstimateAnswers>({
			query: args => ({ url: "/vehicle/insurance/msm", method: "POST", body: args }),
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				const { data } = await queryFulfilled;
				dispatch(updateMSMQuote({ quote: data, vrm: args.registration }));
			},
		}),
		loginUser: builder.mutation<BasicResponse, BasicAuth>({
			query: args => ({ url: "/auth/login", method: "POST", body: { ...args, source: "web" } }),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				const { data } = await queryFulfilled;
				dispatch(updateLoginState({ auth: true, ref: data.ref, msm: data.msmId }));
			},
		}),
		createUser: builder.mutation<BasicResponse, CreateBasicAuth>({
			query: args => ({ url: "/auth/create", method: "POST", body: { ...args } }),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(updateLoginState({ auth: true, ref: data.ref, msm: data.msmId }));
				} catch (error) {
					// Handle later
					console.error(error);
				}
			},
		}),
		logoutUser: builder.mutation<{}, void>({
			query: () => ({ url: "/auth/logout", method: "POST" }),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(logoutUser());
			},
		}),
	}),
});

export const {
	useLazyGetAllVehiclesQuery,
	useLazyGetUserInfoQuery,
	useLazyGetMultiCarQuery,
	useLoginUserMutation,
	useLogoutUserMutation,
	useGetReportQuery,
	useLazyGetReportQuery,
	useLazyGetFullForecastQuery,
	useCreateUserMutation,
	useUpdateUserProfileMutation,
	useUpdateVehicleMSMQuoteMutation,
} = mainApi;
