import { createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "model/enum/loading";
import { FinanceReport } from "model/object/report";
import { HYDRATE } from "next-redux-wrapper";
import type { RootState } from "../index";

export interface ReportState {
	reportId: string;
	report: FinanceReport | null;
	loadingStatus: LoadingStatus;
}

const initialState: ReportState = {
	reportId: "",
	report: null,
	loadingStatus: LoadingStatus.LOADING,
};

export const reportSlice = createSlice({
	name: "report",
	initialState,
	reducers: {
		updateReportId: (state, action) => {
			state.reportId = action.payload;
		},
		updateReport: (state, action) => {
			state.report = action.payload;
			state.loadingStatus = LoadingStatus.SUCCESS;
		},
	},
	extraReducers: {
		[HYDRATE]: (state, action) => {
			state.reportId = action.payload.report.reportId;
		},
	},
});

export const { updateReportId, updateReport } = reportSlice.actions;
export const selectReport = (state: RootState) => state.report;
export default reportSlice.reducer;
