import { useEffect, useState } from "react";

const useDimension = () => {
	const [screenWidth, setScreenWidth] = useState(0);
	const [screenHeight, setScreenHeight] = useState(0);

	const isMobile = screenWidth <= 425;
	const isTablet = screenWidth <= 768;
	useEffect(() => {
		const updateDimension = () => {
			setScreenWidth(window.innerWidth);
			setScreenHeight(window.innerHeight);
		};

		window.addEventListener("resize", updateDimension);

		updateDimension();

		return () => window.removeEventListener("resize", updateDimension);
	}, [screenWidth, screenHeight]);

	return { screenWidth, screenHeight, isMobile, isTablet };
};

export default useDimension;
