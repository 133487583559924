import { getCookie, hasCookie, setCookie } from "cookies-next";
import { v4 as uuidv4 } from "uuid";

export const getStorageValue = (storageKey: string) => {
	const item = window.localStorage.getItem(storageKey);
	return item === null ? "" : item;
};
export const setStorageValue = (storageKey: string, value: string) => {
	window.localStorage.setItem(storageKey, value);
};

export const removeStorageValue = (storageKey: string) => {
	window.localStorage.removeItem(storageKey);
};

export const getDeviceId = () => {
	const key = "carcloud-device-id";
	const hasDeviceId = hasCookie(key);
	if (hasDeviceId) {
		const deviceId = getCookie(key);
		setStorageValue(key, deviceId?.toString()!);
		return deviceId?.toString()!;
	}

	// No device id on cookie, check local storage
	const deviceIdLocalStorage = getStorageValue(key);
	console.log("Storage Key:", deviceIdLocalStorage);
	if (deviceIdLocalStorage) {
		// Present in local storage
		setCookie(key, deviceIdLocalStorage, { domain: ".carcloudcommunity.co.uk", secure: false, maxAge: 60 * 60 * 24 * 365, sameSite: "lax" });
		return deviceIdLocalStorage;
	}

	const newDeviceId = uuidv4();
	setCookie(key, newDeviceId, { domain: ".carcloudcommunity.co.uk", secure: false, maxAge: 60 * 60 * 24 * 365, sameSite: "lax" });
	setStorageValue(key, newDeviceId);

	return newDeviceId;
};

export const getWebSource = () => {
	const key = "user_web_source";
	const webSource = getStorageValue(key);
	if (webSource) {
		return webSource;
	}

	setStorageValue(key, "CARCLOUD");
	return "CARCLOUD";
};
