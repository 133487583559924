import classNames from "classnames";
import Image from "next/image";
import { Button } from "react-bootstrap";
import { BsCheckAll } from "react-icons/bs";
import { FiArrowRight, FiCheck } from "react-icons/fi";

type PrimaryCtaProps = {
	text: string | Element;
	onClick?: () => void;
	disabled?: boolean;
	type?: "submit" | "reset" | "button" | undefined;
	style?: object;
	icon?: string;
	isSubmitting?: boolean;
	className?: string;
	hasArrowRight?: boolean;
	fullWidth?: boolean;
	sent?: boolean;
	arrowRightClassName?: string;
	hasCheckRight?: boolean;
	checkRightClassName?: string;
};

const PrimaryCta = ({
	text,
	onClick,
	disabled = false,
	type = "button",
	style,
	icon,
	isSubmitting = false,
	className = "",
	hasArrowRight = false,
	fullWidth = false,
	sent = false,
	arrowRightClassName = "",
	checkRightClassName = "",
	hasCheckRight = false,
}: PrimaryCtaProps) => {
	return (
		<Button
			onClick={onClick}
			disabled={disabled || isSubmitting}
			className={classNames(`submit flex items-center justify-center !rounded-3xl px-4 py-2.5 text-base focus:bg-blue ${className}`, {
				"!w-max": !fullWidth,
				"!w-auto": fullWidth,
			})}
			type={type}
			style={style}
		>
			{isSubmitting ? (
				<div className="relative h-[25px] w-[100px]">
					<Image src={require("public/compress-loading.gif")} alt="loading" layout="fill" objectFit="contain" />
				</div>
			) : (
				<>
					{icon ? (
						<i className={`bi ${icon}`} style={{ color: "#fff", fontSize: "1.3em" }}>
							<span style={{ color: "#fff" }} className="ps-2">
								{text}
							</span>
						</i>
					) : (
						<>
							<span>{text}</span>
							{hasArrowRight && <FiArrowRight className={`ml-2 text-lg ${arrowRightClassName}`} />}
							{hasCheckRight && <FiCheck className={`ml-2 text-lg ${checkRightClassName}`} />}
							{sent && <BsCheckAll className="ml-2 text-xl" />}
						</>
					)}
				</>
			)}
		</Button>
	);
};

export default PrimaryCta;
