import { Dropdown, Nav, NavDropdown } from "react-bootstrap";
import { useRouter } from "next/router";
import NavItemTitle from "./NavItemTitle";
import { ChildItem, NavigationItem } from "./types";

const NavItem = ({ navItem }: { navItem: NavigationItem }) => {
	const router = useRouter();

	if (navItem.children) {
		const index = navItem.children.findIndex(nav => nav.link.split("/")[1] === router.pathname.split("/")[1]);

		return (
			<NavDropdown title={<NavItemTitle title={navItem.name} Icon={navItem.icon} isActive={index !== -1} />} id="nav-dropdown">
				{navItem.children.map((child: ChildItem, index) => (
					<div key={index}>
						<NavDropdown.Item href={child.link}>{child.name}</NavDropdown.Item>
						{index < navItem.children!.length - 1 && <Dropdown.Divider />}
					</div>
				))}
			</NavDropdown>
		);
	}

	return (
		<Nav.Link href={navItem.link} onClick={navItem.onClick} className="ml-6" active={false}>
			<NavItemTitle Icon={navItem.icon} title={navItem.name} isActive={navItem.link === router.pathname} />
		</Nav.Link>
	);
};

export default NavItem;
