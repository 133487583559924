import { getStorageValue } from "helpers";
import { api } from "helpers/api";
import { calculateAgeWithString } from "helpers/date";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserState, selectUser } from "store/reducers/userReducer";
import { useLazyGetUserInfoQuery, useLogoutUserMutation } from "store/services/main";

const useUser = () => {
	const router = useRouter();
	const [msmBody, setMsmBody] = useState({});
	const user: UserState = useSelector(selectUser);
	const [logoutAction] = useLogoutUserMutation();
	const [fetchUser, { isFetching, isSuccess }] = useLazyGetUserInfoQuery();

	const logout = async (ref?: string) => {
		await logoutAction();
		router.replace(`/login${ref ? `?ref=${ref}` : ""}`);
	};

	useEffect(() => {
		// Updating user source
		if (user.isLoggedIn && user.userRef && user.profile?.webSource === "") {
			api.post("/profile/source", { source: getStorageValue("user_web_source") || "CARCLOUD" });
		}
	}, [user]);

	useEffect(() => {
		if (user.profile) {
			setMsmBody({
				driverAge: calculateAgeWithString(user.profile.dob as string),
				homeOwner: user.profile.msm?.homeOwner ?? false,
				noClaimsDuration: user.profile.msm?.noClaims ?? 0,
				postcode: user.profile.postcode,
				provisionalLicence: user.profile.msm?.licenseType === "provisional",
			});
		}
	}, [user.profile]);

	return { ...user, logout, fetchUser, isFetching, isSuccess };
};

export default useUser;
