import { ChevronLeft } from "react-bootstrap-icons";
import { useRouter } from "next/router";

const BackButton = ({ text = "Go back" }: { text?: string }) => {
	const router = useRouter();
	return (
		<button onClick={() => router.back()} className="btn">
			<div className="d-flex align-items-center">
				<ChevronLeft color="#2ebfe3" />
				<span className="fw-bold ms-2 underline decoration-[#2ebfe3] underline-offset-4" style={{ color: "#2ebfe3" }}>
					{text}
				</span>
			</div>
		</button>
	);
};

export default BackButton;
