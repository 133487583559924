import React from "react";
import classNames from "classnames";
import type { IconType } from "react-icons";

type NavItemTitleProps = {
	title: string;
	Icon: IconType;
	isActive: boolean;
};

const NavItemTitle = ({ title, Icon, isActive = false }: NavItemTitleProps) => {
	return (
		<div className="nav-item me-xl-3 flex items-center">
			<div className="mr-3 flex items-center justify-center rounded-xl bg-blue p-2.5">
				<Icon className="text-2xl" />
			</div>
			<span
				className={classNames("rounded-sm text-sm font-medium uppercase transition-[border] duration-100 hover:border-b-[3px] hover:border-blue", {
					"border-b-[3px] border-blue": isActive,
				})}
			>
				{title}
			</span>
		</div>
	);
};

export default NavItemTitle;
