import classNames from "classnames";

type RegistrationPlateProps = {
	vrm: string;
	size?: "sm" | "lg";
};

const RegistrationPlate = ({ vrm, size = "lg" }: RegistrationPlateProps) => {
	return (
		<div
			className={classNames("registration-plate !w-max rounded-md border-none bg-yellow py-1 !font-mono !font-[1000] text-black", {
				"px-[15px] text-3xl": size === "lg",
				"px-[10px] text-2xl": size === "sm",
			})}
		>
			{vrm}
		</div>
	);
};

export default RegistrationPlate;
