import { useSelector } from "react-redux";
import { MultiCarState, selectMultiCar } from "store/reducers/multiCarReducer";
import { useLazyGetMultiCarQuery } from "store/services/main";

const useMultiCar = () => {
	const multiCarState: MultiCarState = useSelector(selectMultiCar);
	const [fetchMultiCar] = useLazyGetMultiCarQuery();

	return { ...multiCarState, fetchMultiCar };
};

export default useMultiCar;
