import axios from "axios";
import Router from "next/router";
import { getDeviceId } from "./storage";

export const fetcher = (url: string) => axios.get(url, { timeout: 25000 }).then(res => res.data);
export const axiosPost = axios.post;

export const api = axios.create({
	baseURL: "/api",
	withCredentials: true,
});

api.interceptors.request.use(
	async config => {
		config.headers["DeviceId"] = getDeviceId();
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		// If 401 unauthorized
		if (error.response && error.response.status === 401) {
			return Router.push("/login");
		}

		return Promise.reject(error);
	}
);
