import classNames from "classnames";

import RegistrationPlate from "components/MyGarage/RegistrationPlate";
import { formatDate } from "helpers/date";
import { VehicleV2 } from "model/object/vehicle";

type RemoveVehicleSectionProps = {
	vehicle: VehicleV2;
	isPrimary: boolean;
	onSelect: () => void;
};

const RemoveVehicleSection = ({ vehicle, isPrimary, onSelect }: RemoveVehicleSectionProps) => {
	return (
		<div className={classNames("flex cursor-pointer gap-3 rounded-xl py-1 transition-all duration-300", { "bg-blue": isPrimary })} onClick={onSelect}>
			<div className="mx-1 flex w-5/6 gap-4 rounded-l-lg bg-white px-2.5 py-2">
				<div className="w-40">
					<RegistrationPlate vrm={vehicle.registration} />
				</div>
				<div className="flex flex-col justify-center text-left text-[10px] font-[550]">
					<p>
						{vehicle.make} {vehicle.model}
					</p>
					<p>Last registered keeper changed {formatDate(vehicle.keeperStartDate)}</p>
				</div>
			</div>
			<div className="mx-auto flex items-center pr-4 text-white transition-all duration-200">
				<p className="text-center text-[14px] font-bold">Primary</p>
			</div>
		</div>
	);
};

export default RemoveVehicleSection;
