import crypto from "crypto";
import { format } from "date-fns";
import addDays from "date-fns/addDays";
import differenceInDays from "date-fns/differenceInDays";
import { capitalize } from "lodash";
import { AGREEMENT_TYPE_ENUM, AGREEMENT_TYPE_ENUM_TYPE } from "model/enum/agreement";
import { SubscriptionType } from "model/object/subscription";
import { PrevReport } from "model/object/vehicle";

export const currencyFormatter = new Intl.NumberFormat("en-GB", {
	style: "currency",
	currency: "GBP",
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

export const removeMask = (str: string) => (str ? parseFloat(str.replace(/[^0-9.]/g, "")) : 0);
export const addMask = (num: number) => (num ? `£ ${num}` : "");
export const addPhoneMask = (phone: string) => {
	// Extract the country code
	const countryCode = phone.slice(0, 3);
	const mainNumber = phone.slice(3);

	const part1 = mainNumber.slice(0, 3);
	const part2 = mainNumber.slice(3, 6);
	const part3 = mainNumber.slice(6);

	return `(${countryCode}) ${part1} ${part2} ${part3}`;
};
export const removePhoneMask = (phone: string) => phone.replace(/[\s()]/g, "");
export const isZero = (str: string) => removeMask(str) === 0;

export const formatPercent = (percentage: number) => (percentage < 0 ? 1 : percentage);
export const formatBarWidth = (percentage: number, containerWidth: number) => (percentage < 0 ? containerWidth / 2 : percentage * containerWidth);

export const getFinanceCompany = (type: AGREEMENT_TYPE_ENUM_TYPE) => `Your car is registered under a ${AGREEMENT_TYPE_ENUM[type].lowerCaseValue} agreement with`;
export const getCTAText = (onfinance: boolean, reportDate: string) => {
	const parsedReportDate = new Date(reportDate);
	const newReportDate = addDays(parsedReportDate, 30);
	const diff = differenceInDays(new Date(), parsedReportDate);

	if (diff < 30) {
		return {
			// main:'Generate your first finance report, to better understand your car ownership costs.',
			main: onfinance ? "View your finance tracker for free, forever." : "View your valuations & equity report.",
			// sub: `Created ${lightFormat(parsedReportDate, "dd/MM/yy")}. Next month's report is available on ${lightFormat(newReportDate, "dd/MM/yy")}.`,
			sub: onfinance
				? `Finance tracker created ${format(parsedReportDate, "do")} of ${format(parsedReportDate, "LLL yy")}. Next report available ${format(
						newReportDate,
						"do"
				  )} of ${format(newReportDate, "LLL yy")}.`
				: `Report created  ${format(parsedReportDate, "do")} of ${format(parsedReportDate, "LLL yy")}. Next report available ${format(newReportDate, "do")} of ${format(
						newReportDate,
						"LLL yy"
				  )}.`,
		};
	} else {
		return {
			main: "Your finance tracker report has been updated",
			sub: onfinance
				? "A new month could mean new opportunities to save. See how your equity position has changed."
				: "A new month could mean new opportunities to save. See how this car's value has changed.",
		};
	}
};

export const checkCarKeeper = (prevReport: PrevReport, otherField: boolean) => {
	let retData = otherField ? "" : prevReport.keeperRelation;

	if (prevReport.keeperRelation == "" && !prevReport.registeredKeeper) {
		retData = "family";
	}

	if (prevReport.keeperRelation.toLowerCase().includes("other") && !otherField) {
		retData = "other";
	}

	if (prevReport.keeperRelation.toLowerCase().includes("other") && otherField) {
		retData = prevReport.keeperRelation.toLowerCase().replace("other -", "");
	}

	return retData;
};

export const capitalizeAllWord = (str: string) =>
	str
		.split(" ")
		.map(word => capitalize(word))
		.join(" ");

export const encryptText = (text: string) => {
	const key = Buffer.from(process.env.ENCRYPTION_SECRET!, "hex");
	const iv = Buffer.from(process.env.ENCRYPTION_IV!, "hex");

	// @ts-ignore
	let cipher = crypto.createCipheriv("aes-128-cbc", key, iv);
	let encrypted = cipher.update(text);
	encrypted = Buffer.concat([encrypted, cipher.final()]);
	return encrypted.toString("hex");
};

export const maxVehicleAllowed = (type: SubscriptionType) => {
	if (type === "free") {
		return 1;
	} else if (type === "carplus") {
		return 3;
	} else if (type === "fleet") {
		return 10;
	} else {
		return -1;
	}
};

export const maxVehicleText = (type: SubscriptionType) => {
	const baseStr = "You have the maximum allocation of vehicles in your garage.";

	if (type === "free") {
		return `${baseStr} Consider upgrading to carplus or fleet subscription.`;
	} else if (type === "carplus") {
		return `${baseStr} Consider upgrading to fleet subscription.`;
	} else if (type === "fleet") {
		return `${baseStr} Contact us directly if you want greater than 10 vehicle support.`;
	} else {
		return baseStr;
	}
};
