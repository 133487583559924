import { createSlice } from "@reduxjs/toolkit";
import { setStorageValue } from "helpers/storage";
import type { RootState } from "../index";

export interface VehicleFinance {
	financeVRMs: string[];
}

const initialState: VehicleFinance = {
	financeVRMs: [],
};

export const financeStateSlice = createSlice({
	name: "vehicleFinance",
	initialState,
	reducers: {
		setVehicleFinance: (state, action) => {
			state.financeVRMs = action.payload;
		},
		updateVehicleFinance: (state, action) => {
			const updatedVRMs = [...state.financeVRMs];
			updatedVRMs.push(action.payload);
			setStorageValue("finance-vrms", JSON.stringify(updatedVRMs));
			state.financeVRMs = updatedVRMs;
		},
	},
});

export const { setVehicleFinance, updateVehicleFinance } = financeStateSlice.actions;
export const selectVehicleFinanceType = (state: RootState) => state.vehicleFinance;
export default financeStateSlice.reducer;
