import Link from "next/link";

import Image from "next/image";
import { useRouter } from "next/router";
import carCloudLogo from "public/CarCloud-White-Logo-2.svg";

const Footer = () => {
	const router = useRouter();

	return (
		<footer
			className={` z-10 bg-indigo px-20 py-3 text-white tablet:gap-10 tablet:!px-5 ${router.pathname === "/quick-widget/partner" && "!bg-[#101D2D]"} ${
				router.pathname === "/finance-prize-draw" && "!hidden"
			}`}
		>
			{router.pathname !== "/quick-widget/partner" && router.pathname !== "/quick-widget/base" && (
				<div className="flex items-center justify-between">
					<div className="tablet:mr-10">
						<Link href="/" passHref>
							<>
								<Image alt="CarCloud" src={carCloudLogo} height={40} width={140} objectFit="contain" className="cursor-pointer" />
							</>
						</Link>
					</div>

					<div className="flex items-center gap-2.5 tablet:text-sm">
						<a href="https://carcloudcommunity.co.uk/privacy" target="_blank" rel="noreferrer noopener" className="text-white hover:underline">
							Privacy Policy
						</a>
						&mdash;
						<a href="https://carcloudcommunity.co.uk/app-terms-and-conditions" target="_blank" rel="noreferrer noopener" className="text-white hover:underline">
							Terms and Conditions
						</a>
					</div>
				</div>
			)}

			{router.pathname === "/quick-widget/partner" || router.pathname === "/quick-widget/base" ? (
				<div className="flex flex-col gap-2 text-center !text-[8px] text-white">
					<p>
						Pace Cloud Limited (under Company Number 11397547, registered and incorporated in England and Wales) trades as CarCloud Community. Registered address:
						Grosvenor House, 11 St. Pauls Square, Birmingham, B31 RB. Pace Cloud Limited are registered with the Information Commissioner’s Office - ICO (Reference
						ZA539573).
					</p>
					<p>
						The car insurance journey is provided by{" "}
						<a href="http://moneysupermarket.com" target="_blank" rel="noopener noreferrer">
							MoneySuperMarket.com
						</a>{" "}
						Ltd.{" "}
						<a href="http://moneysupermarket.com" target="_blank" rel="noopener noreferrer">
							MoneySuperMarket.com
						</a>{" "}
						Ltd is an appointed representative of MONY Group Financial Ltd, which is authorised and regulated by the Financial Conduct Authority (FCA FRN 303190). Pace
						Cloud Ltd (FRN 841535) trading as CarCloud Community is an appointed representative of MONY Group Financial Limited (FCA FRN 303190). Registered in England
						No. 03157344. Registered Office: MONY Group House, St David’s Park, Exloe, CH5 3UZ.
					</p>
				</div>
			) : (
				<div className="text-center text-sm text-neutral500">
					<p className="my-4">
						Pace Cloud Limited (under Company Number 11397547, registered and incorporated in England and Wales) trades as CarCloud Community. Registered address:
						Grosvenor House, 11 St. Pauls Square, Birmingham, B3 1RB. Pace Cloud Limited are registered with the Information Commissioner’s Office - ICO (Reference
						ZA539573).
					</p>
					{router.pathname !== "/car-insurance-challenge" && (
						<p className="mb-4">
							Pace Cloud Limited (FRN 841535) trading as CarCloud Community is an Appointed Representative of Consumer Credit Compliance Limited who are authorised
							and regulated by the Financial Conduct Authority (FRN 631736). The permissions of Consumer Credit Compliance Limited as a Principal firm allow Pace
							Cloud Limited to undertake Credit Broking. Pace Cloud Limited acts as a credit broker and not a lender, we will receive a fixed commission for all
							completed agreements. You will not pay more as a result of our commission arrangements.
						</p>
					)}
					<p>
						The car insurance journey is provided by{" "}
						<a href="http://moneysupermarket.com" target="_blank" rel="noopener noreferrer">
							MoneySuperMarket.com
						</a>{" "}
						Ltd.{" "}
						<a href="http://moneysupermarket.com" target="_blank" rel="noopener noreferrer">
							MoneySuperMarket.com
						</a>{" "}
						Ltd is an appointed representative of MONY Group Financial Ltd, which is authorised and regulated by the Financial Conduct Authority (FCA FRN 303190). Pace
						Cloud Ltd (FRN 841535) trading as CarCloud Community is an appointed representative of MONY Group Financial Limited (FCA FRN 303190). Registered in England
						No. 03157344. Registered Office: MONY Group House, St David’s Park, Exloe, CH5 3UZ.
					</p>
				</div>
			)}
		</footer>
	);
};

export default Footer;
