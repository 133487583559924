export const cookieBannerStyles = {
	banner: {
		background: "rgba(52, 64, 81, 0.88) url(/img/cookie.png) 20px 50% no-repeat",
		backgroundSize: "20px 20px",
		backgroundColor: "#2ebfe3",
		fontSize: "15px",
		fontWeight: 600,
		position: "fixed",
		bottom: 0,
	},
	button: {
		border: "1px solid white",
		borderRadius: 4,
		width: 66,
		height: 30,
		lineHeight: "32px",
		background: "transparent",
		color: "white",
		fontSize: "14px",
		fontWeight: 600,
		opacity: 1,
		right: 20,
		marginTop: -18,
	},
	message: {
		display: "block",
		padding: "9px 67px",
		lineHeight: 1.3,
		textAlign: "left",
		marginRight: 50,
		color: "white",
	},
	link: {
		textDecoration: "none",
		fontWeight: "bold",
	},
};
