import { Dispatch, SetStateAction, useState } from "react";

import { PrimaryCta } from "components/Buttons";
import { api } from "helpers/api";
import useMultiCar from "hooks/useMultiCar";
import { useRouter } from "next/router";

type PaymentProps = { setStep: Dispatch<SetStateAction<number>>; isUnpaid: boolean };

function Payment({ setStep, isUnpaid }: PaymentProps) {
	const [isLoading, setIsLoading] = useState(false);
	const router = useRouter();
	const { subscription } = useMultiCar();
	const limitReached = subscription?.status === "active" && subscription?.isLocked;

	const updatePaymentMethod = async () => {
		setIsLoading(true);
		try {
			const response = await api.get(`/stripe/payment`);
			await router.push(response.data);
		} catch (error) {
			setIsLoading(false);
			throw new Error(`Received status code 400`);
		}
	};

	return (
		<div className="px-4 !text-indigo">
			<div className="mb-12 flex flex-col gap-3">
				<p className="text-left text-[20px] font-bold mobileL:text-center">{isUnpaid ? "Payment unsuccessful" : "Hi there!"}</p>
				<p className="text-left text-[14px] font-[450] mobileL:text-center">
					{isUnpaid ? (
						<p className="text-[14px] font-[450]">
							Your payment has not yet been received, and your account has been downgraded. No worries! Simply complete your payment and we will promptly reinstate
							your previous account level. Should you require any assistance,{" "}
							<a href="https://carcloudcommunity.co.uk/contact" target="_blank" rel="noreferrer" className="font-extrabold text-black">
								our support team
							</a>{" "}
							is on hand to help.
						</p>
					) : limitReached ? (
						"You have a valid subscription but your account is still locked because you’ve exceeded the max vehicle limit. Please reduce your vehicles or upgrade your subscription to unlock access."
					) : (
						"We've made some changes. Your free account now enables you to keep one vehicle in your CarCloud garage. Please choose which car you want to keep, or upgrade today to a multi-car subscription which also unlocks extra tools and benefits."
					)}
				</p>
			</div>
			<div className="flex flex-col gap-3 !text-[14px] font-[550]">
				{isUnpaid ? (
					<PrimaryCta text="Complete Payment" className="mx-auto !text-[14px]" isSubmitting={isLoading} onClick={updatePaymentMethod} hasArrowRight />
				) : limitReached ? (
					<PrimaryCta text="Upgrade Subscription" className="mx-auto !text-[14px]" onClick={() => router.push("/subscriptions")} hasArrowRight />
				) : (
					<PrimaryCta text="Add Subscription" className="mx-auto !text-[14px]" onClick={() => router.push("/subscriptions")} hasArrowRight />
				)}
				<p onClick={() => setStep(2)} className="cursor-pointer">
					Downgrade my account
				</p>
			</div>
		</div>
	);
}

export default Payment;
