import { differenceInDays, differenceInMonths, differenceInYears, format, formatISO, parse, parseISO } from "date-fns";
import pluralize from "pluralize";

export const getAccentColourByDifferenceInDays = (daysDiff: number): string => {
	if (daysDiff > 30) {
		return "#53C736";
	}

	if (daysDiff <= 30 && daysDiff >= 14) {
		return "#FFAA1E";
	}

	if (daysDiff < 14) {
		return "#FF1E59";
	}
	return "";
};

export function convertDateStringToNormalDate(dateString: string) {
	const formattedDate = format(parse(dateString.slice(0, 8), "yyyyMMdd", new Date()), "MMM do, yyyy");
	return formattedDate;
}

export function formatDate(dateString: string) {
	const formattedDate = format(new Date(dateString), "MMM do, yyyy");
	return formattedDate;
}

export function formatShortDate(date: Date) {
	const formattedDate = format(date, "dd/MM/yyyy");
	return formattedDate;
}

export function formatShortDateFromString(date: string) {
	const formattedDate = format(new Date(date), "dd/MM/yyyy");
	return formattedDate;
}

export const getDateDetails = (targetDate: Date) => {
	const currentDate = new Date();
	const diffMonths = differenceInMonths(targetDate, currentDate);
	const diffDays = differenceInDays(targetDate, currentDate);

	return {
		diffDays,
		diffMonths,
		progressColor: diffDays > 0 ? "#26B731" : "#C11414",
		dueDate: `Due ${format(targetDate, "MMM do")} ${targetDate.getFullYear()}`,
		dateLeft:
			diffMonths >= 1
				? `${pluralize("month", diffMonths, true)} left...`
				: diffDays >= 0
				? `${pluralize("day", diffDays, true)} left...`
				: diffMonths < 0
				? `${pluralize("month", Math.abs(diffMonths), true)} ago...`
				: `${pluralize("day", Math.abs(diffDays), true)} ago...`,
	};
};

export function monthsBetweenDates(startDateStr: string, endDateStr: string) {
	// Parse the date strings into Date objects
	const startDate = parseISO(startDateStr);
	const endDate = parseISO(endDateStr);

	// Calculate the difference in months
	return differenceInMonths(endDate, startDate);
}

export const calculateAge = (dob: Date) => {
	const today = new Date();
	return differenceInYears(today, dob);
};

export const calculateAgeWithString = (dob: string) => {
	const today = new Date();
	return differenceInYears(today, new Date(dob));
};

export const convertDateToISO = (dateString: string) => {
	const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());

	return formatISO(parsedDate);
};
